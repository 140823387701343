<template>
  <div class="leistungblock">
    <div class="leistungblock__wrapper">
      <div class="leistungblock__content">
        <HeaderVideo :videopath="'assets/videos/like.mp4'" />
        <div class="block__text">
          Es gibt unzählige Marken. Manche hat man gern. Manche liebt man.
        </div>
        <div class="block__p">
          Wir wollen, dass Ihre Marke geliebt wird, dass aus Ihren Zielgruppen
          Zielgroupies werden. Auf diesem Weg ‒ von der Brand zur Love Brand ‒
          wollen wir Sie gerne unterstützen. Mit viel Liebe, Leidenschaft und
          einer gesunden Nie-zufrieden-Mentalität.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderVideo from "@/components/common/header/HeaderVideo";
export default {
  components: {
    HeaderVideo,
  },
};
</script>

<style></style>
