<template>
  <div class="sddblock">
    <div class="sddblock__wrapper">
      <!-- <AppLoveBrands /> -->
      <div class="ssdblock__item">
        <div class="ssdblock__sec">
          <Strat :allowScroll="false" :pin="false" />
        </div>
      </div>
      <div class="ssdblock__item">
        <div class="ssdblock__sec">
          <SDDInfo>
            <template v-slot:big
              >Eine gute Brand-Strategie ist das Fundament einer Love-Brand. Sie
              schafft die Basis wie Pizzateig. Mit einem starken Charakter
              positionieren wir Ihre Marke nachhaltig am Markt und sorgen für
              den ersten Herzschlag.</template
            >
            <template v-slot:small
              >Wen sprechen wir wie an? Was ist unser Ziel? Gemeinsam entwickeln
              wir eine Vision der zukünftigen Love-Brand. Die daraus gewonnenen
              Erkenntnisse nutzen wir als Instrument für die
              Marken-Positionierung. So schaffen wir Relevanz und steigern die
              Begehrlichkeit.</template
            >
            <template v-slot:special--one>
              <div>Analyse</div>
              <div>Markenstrategie</div>
              <div>Konzept</div>
              <div>Leitidee</div></template
            >
            <template v-slot:special--two>
              <div>Webentwicklung</div>
              <div>SEO / SEA</div></template
            >
          </SDDInfo>
        </div>
      </div>
      <div class="ssdblock__item">
        <div class="ssdblock__sec">
          <Design :allowScroll="false" :pin="false" />
        </div>
      </div>
      <div class="ssdblock__item">
        <div class="ssdblock__sec">
          <SDDInfo>
            <template v-slot:big
              >Die Strategie setzt den ersten Herzschlag. Mit unserem Design
              wird daraus ein Donnerschlag. Unverwechselbare, begehrliche Formen
              beleben Ihre Marke zum Leben und knüpfen ein Freundschafts-Band
              zwischen Kunde und Marke.</template
            >
            <template v-slot:small
              >Ein attraktiver, stringenter und state-of-the-artiger
              Gesamtauftritt für alle Plattformen sorgt nicht nur für eine große
              Wiedererkennung, sondern macht Ihre Love Brand
              unverwechselbar.</template
            >
            <template v-slot:special--one>
              <div>Corporate Design</div>
              <div>Packaging Design</div>
              <div>Print Design</div>
              <div>Editorial Design</div></template
            >
            <template v-slot:special--two>
              <div>Webdesign</div>
              <div>App Design</div></template
            >
          </SDDInfo>
        </div>
      </div>
      <div class="ssdblock__item">
        <div class="ssdblock__sec">
          <Dialog :allowScroll="false" :pin="false" />
        </div>
      </div>
      <div class="ssdblock__item">
        <div class="ssdblock__sec">
          <SDDInfo>
            <template v-slot:big
              >Ihre Marke hat jetzt ein Gesicht, sie muss nur noch sprechen.
              Aber wen sprechen wir an? Und wenn ja, wie viele?</template
            >
            <template v-slot:small
              >Gut gemachte Love Brands sind individuell, kontinuierlich und
              berühren das Herz wie Katzenbabys. Egal ob online, offline oder
              Above jeder Line ‒ sie finden überall die richtige Ansprache. Und
              auf allen Kanälen gilt: Was besser aussieht, verkauft sich auch
              besser und kommt schneller ins Gespräch.<br />Denn Liebe macht
              nicht blind, sondern Marken sichtbar erfolgreich.</template
            >
            <template v-slot:special--one>
              <div>Werbemaßnahmen</div>
              <div>Text</div>
              <div>Video</div>
              <div>Fotografie</div>
              <div>Illustration</div>
            </template>
            <template v-slot:special--two>
              <div>Social Media</div>
              <div>App Entwicklung</div>
              <div>Online Werbemaßnahmen</div></template
            >
          </SDDInfo>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SDDInfo from "./SDDInfo";
// import AppLoveBrands from "@/components/ui/buttons/AppLoveBrands.vue";

import Strat from "./Strat";
import Dialog from "./Dialog";
import Design from "./Design";
export default {
  components: { Strat, Design, Dialog, SDDInfo },
};
</script>

<style></style>
