<template>
  <div class="page page__leistungen">
    <LeistungsBlock class="section" />
    <SDDBlockLeistung class="section" />
    <GalleryProjekts class="section" />
    <KundenBlock class="section" />
    <ContactBlock class="section" />
    <TheFooter class="section" />
  </div>
</template>

<script>
import LeistungsBlock from "@/components/common/leistungen/LeistungsBlock.vue";
import SDDBlockLeistung from "@/components/common/sdd/SDDBlockLeistung.vue";
import GalleryProjekts from "@/components/common/gallery/GalleryProjekts.vue";
import KundenBlock from "@/components/common/kunden/KundenBlock.vue";
import ContactBlock from "@/components/common/contact/ContactBlock.vue";
import TheFooter from "../components/common/footer/TheFooter.vue";

import { useStore } from "vuex";

export default {
  components: {
    LeistungsBlock,
    ContactBlock,
    SDDBlockLeistung,
    GalleryProjekts,
    KundenBlock,
    TheFooter,
  },

  setup() {
    const store = useStore();
    // MENU STYLINGS:

    return { store };
  },
};
</script>
<style
  lang="
    scss"
></style>
