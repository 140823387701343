<template>
  <div class="sddinfo">
    <div class="sddinfo__wrapper">
      <div class="sddinfo__content">
        <div class="sddinfo__blockleft">
          <div class="sddinfo__text--big">
            <slot name="big"></slot>
          </div>
          <div
            class="sddinfo__text--small hide--small"
            :class="{ showMore: showAll }"
          >
            <slot name="small"></slot>
          </div>
        </div>
        <div class="sddinfo__blockright">
          <div
            class="sddinfo__text--special hide--small"
            :class="{ showMore: showAll }"
          >
            <slot name="special--one"></slot>
          </div>
          <div
            class="sddinfo__text--special hide--small"
            :class="{ showMore: showAll }"
          >
            <div><b>ONLINE</b></div>
            <slot name="special--two"></slot>
          </div>
        </div>
      </div>

      <div class="sddinfo_item">
        <AppButton
          v-if="showAll"
          class="ssdinfo__btn"
          @click.prevent="setShowAll()"
          >Weniger lesen</AppButton
        >
        <AppButton v-else class="ssdinfo__btn" @click.prevent="setHideAll()"
          >Mehr lesen</AppButton
        >
      </div>
    </div>

    <div></div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import AppButton from "@/components/ui/buttons/AppButton.vue";
import { ref } from "@vue/reactivity";
export default {
  components: { AppButton },
  setup() {
    const showAll = ref(false);

    function setShowAll() {
      console.log("setShowAll");
      showAll.value = false;
      console.log(ScrollTrigger.getAll());
      ScrollTrigger.getAll().forEach((element) => {
        element.refresh();
      });
    }
    function setHideAll() {
      console.log("setHideAll");
      showAll.value = true;

      console.log(ScrollTrigger.getAll());
      ScrollTrigger.getAll().forEach((element) => {
        element.refresh();
      });
    }
    return {
      showAll,
      setShowAll,
      setHideAll,
    };
  },
};
</script>

<style></style>
